import "./Footer.css";

import { AiFillInstagram } from "react-icons/ai";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { Link } from "react-router-dom";
import { FaTwitter } from "react-icons/fa6";

import phone from "../images/phone.svg"
import message from "../images/message.svg"
import Address from "../images/Address.svg"

import { FaXTwitter } from "react-icons/fa6";


export default function Footer() {
  return (
    <div className="footer-bg ">
      {/* <div className=" d-md-flex flex-wrap justify-content-between align-items-center foot-pad">
        <div className="d-flex flex-wrap justify-content-initial gap-3 align-items-center">
          <div className=" logo-txt mt-2 logo col-md-2 col-12 mx-md-3">
          
            <img
              src={require("../images/logo-dark.png")}
              alt="Logo"
              className="img-fluid mb-1 img "
           
            />
           
          </div>
          <div className="vr text-white d-md-block d-none"></div>
          <div className=" menulinks col-12 col-md-6">
            <p className="text-white px-lg-0 px-3">
              A leading IT company that provides best of technology for your
              business to achieve it’s goals.
            </p>
          </div>
        </div>
        <div className="col-lg-3  col-md-4 text-md-center social-section">
          <h5 className="text-white text-center  ps-2 mt-lg-2 ps-md-0">
            Social Links
          </h5>
          <div className="d-flex flex-wrap justify-content-center social">
            <div className="insta">
              <Link
                to="https://www.instagram.com/datartinfotech/?igsh=MWtseTJkc2o2NHpnOA%3D%3D"
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiFillInstagram fill="#D3D3D3" />
              </Link>
            </div>
            <div className="facebook">
              <Link
                to="https://www.facebook.com/datartinfotech"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebookF fill="#D3D3D3" />
              </Link>
            </div>
            <div className="linkedin">
              <Link
                to="https://in.linkedin.com/company/datart-solutions"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedinIn fill="#D3D3D3" />
              </Link>
            </div>
            <div className="twitter">
              <Link
                to="https://twitter.com/DatartSolutions"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={require("../images/twitter-svg.png")}
                  alt="Twitter"
                  className="img-fluid w-75"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>

      <h5 className="text-center text-white">Contact Info</h5>
      <div className="d-flex flex-wrap justify-content-center gap-4 mt-3 mt-lg-0">
        <a href="tel:+91 9552238055" className="text-decoration-none text-white" ><img src={phone} className="img-fluid" /> &nbsp; +91 9552238055</a>
      
        <a href="mailTo:hello@datartinfotech.com" className="text-decoration-none text-white" ><img src={message} className="img-fluid" /> &nbsp; hello@datartinfotech.com</a>
      </div>
      <p  className="text-decoration-none text-white  text-center mt-3" > <img src={Address} className="img-fluid" /> &nbsp; 203, Pentagon 2, Magarpatta, Hadapsar-411028</p>

      <div className="text-center container mt-4 ">
        <p className="secondary-light-color mb-0 pb-3  small-size">
          Copyright 2024. All Rights Reserved. Designed & Developed by Datart
          Infotech
        </p>
      </div> */}
      <div className="pt-4">
        <div className="d-flex flex-wrap p-3  justify-content-between ">
          <div className="col-md-4 col-12">
            <div className=" logo-txt mt-2 logo  mx-md-3 me-md-0 me-5 mx-5">
              <img
                src={require("../images/Logolight1.png")}
                alt="Logo"
                // width="45%"

                className="img-fluid mb-1 img  col-md-7 col-lg-5 col-8"
              />
            </div>
            <hr className="my-3" style={{ borderColor: "#FFFFFF" }} />

            {/* for mob */}
            <div className="col-12 col-md-3 mx-md-5 mx-3 d-md-none d-block ">
              <h3 className="text-white">Contact Us</h3>
              <div className="mt-3">
                <a
                  href="tel:+91 9552238055"
                  className="text-decoration-none textcolor d-flex align-items-center"
                >
                  <img src={phone} className="img-fluid" /> &nbsp; +91
                  9552238055
                </a>
              </div>
              {/* <br /> */}
              <div className="mt-3">
                <a
                  href="mailTo:hello@datartinfotech.com"
                  className="text-decoration-none textcolor d-flex align-items-center"
                >
                  <img src={message} className="img-fluid" /> &nbsp;
                  hello@datartinfotech.com
                </a>
              </div>
              {/* <br/> */}
              <div className="mt-3 ">
                <a className="text-decoration-none textcolor   d-flex align-items-center">
                  <img src={Address} className="img-fluid m" /> &nbsp;
                  <div className="col-9 mx-2 ">
                    410, Amanora Chambers, Amanora Park Town, Hadapsar, Pune,
                    Maharashtra 411028
                  </div>
                </a>
              </div>
            </div>

            <div className="d-flex   social col-10 mx-md-2 mx-auto  align-items-center  ">
              <div className="insta">
                <Link
                  to="https://www.instagram.com/datartinfotech/?igsh=MWtseTJkc2o2NHpnOA%3D%3D"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <AiFillInstagram fill="#FFFFFF" />
                </Link>
              </div>
              <div className="facebook">
                <Link
                  to="https://www.facebook.com/datartinfotech"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebookF fill="#FFFFFF" size={25} />
                </Link>
              </div>
              <div className="linkedin">
                <Link
                  to="https://in.linkedin.com/company/datart-infotech"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedinIn fill="#FFFFFF" />
                </Link>
              </div>
              <div className="twitter">
                <Link
                  to="https://x.com/i/flow/login?redirect_after_login=%2FDatartInfotech"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {/* <img
                    src={require("../images/twitter-svg.png")}
                    alt="Twitter"
                    className="img-fluid "
                  /> */}
                  {/* <FaTwitter fill="#FFFFFF" size={30} /> */}
                  <FaXTwitter fill="#FFFFFF" size={30} />
                </Link>
              </div>
            </div>
            <p className="textcolorfooter mx-md-3  d-md-block d-none">
              © Datart Infotech - 2024 | All Rights Reserved.
            </p>
            <p className="textcolorfooter mx-md-3 text-center d-md-none">
              © Datart Infotech - 2024 | All Rights Reserved.
            </p>
          </div>
          <div className="col-12 col-md-3 mx-md-5 mx-5 d-md-block d-none mt-1">
            <h3 className="text-white">Contact Us</h3>
            <div className="mt-3">
              <a
                href="tel:+91 9552238055"
                className="text-decoration-none textcolorcont forborder"
              >
                <img src={phone} className="img-fluid" /> &nbsp; +91 9552238055
              </a>
            </div>
            {/* <br /> */}
            <div className="mt-3">
              <a
                href="mailTo:hello@datartinfotech.com"
                className="text-decoration-none textcolorcont forborder"
              >
                <img src={message} className="img-fluid" /> &nbsp;
                hello@datartinfotech.com
              </a>
            </div>
            {/* <br/> */}
            <div className="mt-3 ">
              <a className="text-decoration-none textcolorcont m pb-5  d-flex">
                <img src={Address} className="img-fluid" /> &nbsp;
                <div className="col-12 mx-3">
                  410, Amanora Chambers, Amanora Park Town, Hadapsar, Pune,
                  Maharashtra 411028
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
